
*, html, body
  margin: 0
  padding: 0
  font-family: 'Gilroy'
  font-style: normal
.react-multiple-carousel__arrow
  height: 70px
  width: 70px
  text-decoration: none
  border-radius: 100px
  transition: 300ms
  background: rgba(5, 22, 34, 0.6)
  box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5)
  &.react-multiple-carousel__arrow--right
    &:before
      content: ">"
  &.react-multiple-carousel__arrow--left
    &:before
      content: "<"
  &:after
    content: ''
    position: absolute
    top: -1.52px
    left: -2px
    width: 100%
    height: 100%
    border-radius: 100px
    padding: 1.52px
    background: linear-gradient(173deg,#8c6034 11.69%,#a9824f 22.66%,#f2d893 46.82%,#ffe79f 50.87%,#d7b674 64.67%,#96662e 88.34%)
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
    -webkit-mask-composite: xor

  &:before
    width: 100%
    height: 100%
    font-weight: 600
    font-size: 16px
    line-height: 19px
    display: flex
    align-items: center
    text-align: center
    letter-spacing: 0.24em
    text-transform: uppercase
    justify-content: center
    background: linear-gradient(173deg,#8c6034 11.69%,#a9824f 22.66%,#f2d893 46.82%,#ffe79f 50.87%,#d7b674 64.67%,#96662e 88.34%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    -webkit-mask: none
    background-clip: text
    text-fill-color: transparent
  &:hover
    background: #14A0FF99
    box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5)
    transform: scale(1)
  &.up:before
    letter-spacing: 0
    line-height: 0
    height: 90%
    margin: 4px 0

button
  background: #7CA934
  box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5)
  border-radius: 5px
  width: 180px
  height: 50px

a, button
  font-weight: 600
  font-size: 16px
  line-height: 19px
  color: #FFFFFF

.App
  background: #051622
  padding: 0
  margin: 0
  position: relative
  .App-header
    width: 100%
    height: 50px
    padding: 25px 0
    position: static
    top: 0
    left: 0
    background: transparent
    display: flex
    justify-content: space-around
    @media screen and (max-width: 550px)
      justify-content: space-between
      margin: 0 25px
      width: calc(100% - 50px)
    img
      width: 190px
      height: 40px
      @media screen and (max-width: 550px)
        height: 22px
        width: 100px
    .menu
      display: flex
      justify-content: space-around
      width: 500px
      height: 50px
      align-items: center
      a
        color: #fff
        text-decoration: none
        font-size: 1.2rem
        font-weight: 600
        &:hover
          color: #00b4d8
      a
        width: 80px
        height: 19px

  .main
    text-align: center
    .slider
      background-repeat: no-repeat !important
      background-size: 80% 90% !important
      background-position: center!important
      width: 100%
      padding-top: 151px
      @media screen and (max-width: 550px)
        padding-top: 0px
      .slider__item
        .slider__item__content
          display: flex
          flex-direction: row
          flex-wrap: nowrap
          align-content: center
          justify-content: center
          align-items: center
          @media screen and (max-width: 550px)
            font-weight: 700
            font-size: 14px
            line-height: 14px
          .slider__item__content__left
            display: flex
            flex-direction: column
            flex-wrap: nowrap
            align-content: center
            justify-content: center
            align-items: center
            p
              margin-top: 20px
            .slider__item__content__text__title
              font-weight: 700
              font-size: 30px
              line-height: 30px
              color: #FFFFFF
            .slider__item__content__text__description
              font-weight: 700
              font-size: 80px
              line-height: 80px
              background-image: linear-gradient(135deg, #8C6034 11.69%, #A9824F 22.66%, #F2D893 46.82%, #FFE79F 50.87%, #D7B674 64.67%, #96662E 88.34%)
              color: transparent
              -webkit-background-clip: text
              background-clip: text
              @media screen and (max-width: 550px)
                font-weight: 700
                font-size: 40px
                line-height: 40px
            .slider__item__content__button
              z-index: 2
              margin-top: 40px
          img
            width: 700px
            height: 100%
            @media screen and (max-width: 550px)
              width: 90%
              margin-right: 10%
              height: auto
      .react-multi-carousel-dot-list
        margin: 0
        button
          position: relative
          transition: 300ms
          background: transparent
          box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5)
          &:after
            content: ''
            position: absolute
            top: -1.52px
            left: -2px
            width: 100%
            height: 100%
            border-radius: 100px
            padding: 1.52px
            background: linear-gradient(173deg,#8c6034 11.69%,#a9824f 22.66%,#f2d893 46.82%,#ffe79f 50.87%,#d7b674 64.67%,#96662e 88.34%)
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
            -webkit-mask-composite: xor

          &:before
            width: 100%
            height: 100%
            font-weight: 600
            font-size: 16px
            line-height: 19px
            display: flex
            align-items: center
            text-align: center
            letter-spacing: 0.24em
            text-transform: uppercase
            justify-content: center
            background: linear-gradient(173deg,#8c6034 11.69%,#a9824f 22.66%,#f2d893 46.82%,#ffe79f 50.87%,#d7b674 64.67%,#96662e 88.34%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            -webkit-mask: none
            background-clip: text
            text-fill-color: transparent

    .form
      background-size: 100% 100% !important
      background-position: center !important
      min-height: 1260px
      display: flex
      justify-content: center
      align-items: center
      align-content: center
      .form__content
        background-repeat: no-repeat!important
        background-size: 90% 100% !important
        background-position-x: 90% !important
        width: 70% !important
        height: 800px!important
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        align-items: center
        justify-content: center
        align-content: center
        @media screen and (max-width: 550px)
          width: 100% !important
          height: 600px!important
          background-size: 100% 100% !important
          background-position-x: 0 !important
        .form__field
          display: flex
          flex-direction: column
          flex-wrap: nowrap
          width: 415px
          margin: 10px 0
          color: #ffffff
          text-align: left
          @media screen and (max-width: 550px)
            width: 250px
          label
            font-weight: 700
            font-size: 24px
            line-height: 24px
            color: #FFFFFF
            margin-left: 30px
            margin-bottom: 10px
          input
            width: calc(100% - 60px)
            height: 50px
            background: #051622
            border: 1px solid #FFFFFF
            border-radius: 5px
            padding: 0 30px
            font-weight: 600
            font-size: 16px
            line-height: 19px
            color: #ffffff
            &:focus
              outline: none
            &[type="checkbox"]
              position: absolute
              z-index: -1
              opacity: 0
        button
          width: 415px
          @media screen and (max-width: 550px)
            width: 250px
        .mobile-button-to-url
          width: calc(60% - 8px)
          margin-left: calc(7% + 18px)
          @media screen and (max-width: 550px)
            width: 250px
            margin-left: 0
        .vertical
          width: 60%
          display: flex
          flex-flow: row
          margin-top: 150px
          margin-bottom: 15px
          margin-left: 7%
          .form__field
            margin: 0 15px
          @media screen and (max-width: 500px)
            margin: 15px auto
            flex-direction: column
            flex-wrap: nowrap
            align-content: center
            justify-content: center
            align-items: center
            .form__field
              margin: 15px 0
  .end_block
    display: flex
    flex-direction: column
    align-items: center
    .mobile-button-to-url

  .App-footer
    width: 100%
    height: 750px
    margin-top: -350px
    position: relative
    @media screen and (max-width: 550px)
      height: 550px
      margin-top: 50px
      background-position: bottom!important
      background-size: 100% 125% !important
    .footer__content
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      display: flex
      flex-direction: column
      align-content: center
      align-items: center
      justify-content: space-between
      flex-wrap: nowrap
      .logos, .social
        display: flex
        margin-bottom: 10px
        @media screen and (max-width: 550px)
          flex-direction: row
          flex-wrap: wrap
          justify-content: center
          margin-bottom: 30px
        .logo
          width: 100%
          @media screen and (max-width: 550px)
            width: 27%
        a
          display: flex
          margin: 0 7.5px
          h6
            font-weight: 600
            font-size: 20px
            line-height: 29px
            margin-left: 5px
      h4
        margin: 15px 0
        font-weight: 600
        font-size: 14px
        line-height: 17px
        color: #FFFFFF

.gooey
  display: flex
  justify-content: center
  width: 142px
  height: 80%
  margin: 0 auto
  filter: opacity(1)
  .dot
    position: absolute
    width: 16px
    height: 16px
    top: 12px
    left: 15px
    background: #000
    border-radius: 50%
    transform: translateX(0)
    animation: dot 2.8s infinite
  .dots
    transform: translateX(0)
    margin-top: 12px
    margin-left: 31px
    animation: dots 2.8s infinite
    span
      display: block
      float: left
      width: 16px
      height: 16px
      margin-left: 16px
      background: #000
      border-radius: 50%

@keyframes dot
  50%
    transform: translateX(96px)
@keyframes dots
  50%
    transform: translateX(-31px)


